import React, { useState } from 'react'

import Main from '../components/Main'

function EnterprisePage() {
  return (
    <Main activePane='enterprise' />
  )
}


export default EnterprisePage
